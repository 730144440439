

.about {
  @apply my-3 h-full flex flex-col gap-y-3 bg-white p-5 sm:py-6 sm:px-8;
  width: 90%;
  max-width: 1200px;
  font-size: 1.1rem;

  & .head {
    @apply pb-1 border-b border-gray-200;

    & small {
      @apply text-gray-500;
    }
  }

  .h4 {
    margin: 5px 0px;
    padding: 0px 0px;
  }
  
}

.mission {
  @apply  p-5 sm:py-6 sm:px-8;
  margin: 20px 0px;
  padding: 50px 2rem;
  
  & .head {
    @apply pb-1 border-b border-gray-200;
    margin-bottom: 1rem;
    max-width: 200px;
  }

  .mission-statement {
    justify-content: space-between;

    img {
      width: 45%;
      object-fit: cover;
    }
    p {
      width: 50%;
      font-size: 1.2rem;
      padding-top: 20px;
    }
  }

  
  @media screen and (max-width: 700px) {
      
    .mission-statement {
      flex-direction: column;

      
      img, p {
        width: 100%;
        margin: auto;
        display: block;
      }
    }



  }
}

.objectives {
  @apply  p-5 sm:py-6 sm:px-8;
  margin: 20px 0px;
  padding: 20px 2rem;
  
  & .head {
    @apply pb-1 border-b border-gray-200;
    margin-bottom: 1rem;
    max-width: 250px;
  }

  .mission-statement {
    justify-content: space-between;

    img {
      width: 45%;
      object-fit: cover;
    }
    div {
      width: 50%;
      font-size: 1.1rem;
      padding-top: 20px;

      p {
        margin: 5px 0px;
      }
    }
  }

  
  @media screen and (max-width: 700px) {
      
    .mission-statement {
      flex-direction: column-reverse;

      
      img, p, div {
        width: 100%;
        margin: auto;
        display: block;
      }
    }

  }
}

.about-confidentiality {
  width: 95%;
  font-size: 1.0rem;
  margin: 50px auto;
}