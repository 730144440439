/* Top home header */
.header {
  & .mail {
    @apply cursor-pointer relative;

    & span {
      @apply bg-indigo ease-in-out left-0 -bottom-1 absolute h-[1px] w-full;
    }
  }
}

@media screen and (max-width: 700px) {
  .home-feedbacks-dropdown {
    display: none;
  }
  a.mobile-full {
    display: block;
    width: 70%;
    margin: 0px auto;
  }
}
.social-icon {
  @apply h-6 w-6 fill-current;
}

/* Home navbar */
.home-navbar {
  @apply flex w-full shadow-sm justify-between items-center bg-white px-8 py-6 font-bold;

  & .nav-toggle,
  & .nav-close {
    @apply block lg:hidden visible;
  }

  & .nav-close {
    @apply absolute top-3 right-4;
  }

  & .items {
    @apply flex flex-col lg:flex-row gap-10;

    & span {
      @apply uppercase text-base tracking-tight text-gray-800;
    }
  }

  & .auth-action {
    @apply flex gap-x-4 font-bold items-center;
  }
}

.home-menu-btn {
  @apply shadow-lg;
}

.home-menu-btn.fixed-btn {
  @apply fixed top-0 right-8 z-40;
}

.home-navbar .items-container {
  @apply fixed top-0 lg:sticky lg:top-0 bg-white z-50 lg:z-0 w-[70%] h-full lg:h-10 shadow-lg lg:shadow-none p-12 lg:p-0 duration-300 flex flex-col lg:flex-row justify-between lg:items-center;
}

.impactreport {
  @apply w-full p-12 lg:px-24 lg:py-12 bg-indigodark text-white flex flex-col xl:flex-row xl:items-center gap-12;

  & h4 {
    @apply text-2xl font-bold text-cyan mb-2;
  }

  & p {
    @apply text-base;
  }

  & button {
    @apply bg-cyan w-full xl:w-96 py-2 px-4 text-base font-medium rounded-lg;
    text-shadow: 2px 0 2px rgba(0, 0, 0, 0.1);
  }
}

.impactreport .counts {
  @apply mt-6 flex items-baseline gap-x-10;

  & div {
    @apply flex flex-col;
  }

  & span {
    @apply text-lg font-semibold text-cyan;
  }
  
}

.home-footer-container {
  @apply w-full p-12 lg:px-24 lg:py-12 bg-indigodark text-white;
}

.home-footer {
  @apply flex flex-wrap pb-6 gap-y-12 gap-x-24;

  & .site-info {
    @apply flex flex-col items-start gap-y-6 md:w-1/3;
  }

  & .site-links {
    @apply flex flex-wrap gap-x-12 gap-y-6 md:mt-12;
  }
}

.home-footer .site-links div {
  @apply flex flex-col gap-y-4;

  & h3 {
    @apply text-lg font-semibold;
  }

  & ul {
    @apply flex flex-col gap-y-3;

    & li,
    & a {
      @apply flex items-center gap-2;
    }
  }
}

.subscribe {
  @apply w-full p-12 lg:px-24 lg:py-12 bg-blue-50;
}

.subscribe-head {
  @apply flex flex-col gap-y-2;

  & h1 {
    @apply text-darkcyan;
  }
}

.subscribe-box {
  @apply relative text-base w-full lg:w-96 mt-6;

  & input {
    @apply pl-12 pr-24 py-3 w-full;
  }

  & button {
    @apply absolute top-4 -right-8 bg-darkcyan px-6 py-3 text-white font-semibold;
  }
}

.metrics {
  @apply relative w-full p-12 lg:px-24 lg:py-12 flex flex-col gap-y-3;

  /* &::before {
    content: '';
    @apply absolute bg-indigo -top-10 -left-36 h-96 w-36 z-[-1] rotate-12;
  } */

  & .nigeriamap {
    @apply w-full lg:w-1/2;
  }
}

.instructions {
  @apply flex flex-col gap-y-8;
}

.instruction-wrapper {
  @apply flex flex-col gap-y-12;
}

.instruction {
  @apply flex gap-x-4 items-center text-gray-600 text-lg;

  & .number {
    @apply bg-indigodark w-7 h-7 rounded-full text-white text-sm flex justify-center items-center;
  }
}
