.feedback-page {
  @apply bg-gray-100 min-h-screen;
}

.feedback-page .dashboardheader {
  @apply bg-white px-5 py-4 flex flex-wrap gap-6 justify-between items-center;

  & .head {
    & h4 {
      @apply text-gray-900;
    }
  }

  & small {
    @apply text-gray-500;
  }
}

.feedback-page .dashboardheader .stats {
  @apply flex items-center gap-4 divide-x divide-gray-50;

  & .stat {
    @apply flex items-center gap-4 bg-amber-50 py-2 px-4 rounded-lg;

    & svg {
      @apply h-14 text-amber-600 bg-amber-100 rounded-full p-2;
    }

    & div {
      @apply space-y-1;
    }
  }
}

.submissions {
  @apply p-4;
}

.submissions .table_container {
  @apply overflow-x-auto p-4;
}

.submissions .table {
  @apply w-full;
  border-spacing: 0;

  & td {
    @apply px-4 py-5 border-t border-gray-100;
  }

  & tbody {
    & tr:first-child td {
      @apply border-t-0;
    }

    & tr:nth-child(n) td {
      @apply bg-gray-200;
    }

    & tr:nth-child(2n) td {
      @apply bg-white;
    }
  }
}

.submissions .table th {
  @apply bg-white px-4 py-3 border-b border-gray-900 font-bold text-left;

  &.up {
    background-image: url("./images/up_arrow.png");
  }
  &.down {
    background-image: url("./images/down_arrow.png");
  }
  &.default {
    background-image: url("./images/default.png");
  }

  &.up,
  &.default,
  &.down {
    @apply cursor-pointer bg-no-repeat;
    background-position: center right;
  }
}
