.requestdata-header {
  @apply flex flex-wrap items-center justify-between gap-6 bg-indigodark p-5 sm:py-6 sm:px-8;
}

.requestdata {
  @apply my-3 h-full flex flex-col gap-y-3 bg-white p-5 sm:py-6 sm:px-8;

  & .head {
    @apply pb-3 border-b border-gray-200;

    & small {
      @apply text-gray-500;
    }
  }
}

.requestdata .form {
  @apply flex flex-col gap-y-4;

  & .form-field-wrapper {
    @apply pb-4 border-b border-gray-200 flex flex-col gap-y-4;
  }

  & .form-field {
    @apply flex flex-col gap-y-1;

    & label {
      @apply text-sm font-semibold text-gray-700 tracking-tight;
    }

    & input {
      @apply bg-gray-50 rounded-lg py-2 px-4 text-base ring-1 ring-gray-200 tracking-tight;
    }
  }

  & .btn {
    @apply bg-indigo text-white text-base font-semibold py-2 px-6 w-max border-b-4 border-indigodark rounded-lg drop-shadow-lg active:drop-shadow-none active:border-0 active:translate-y-1 tracking-tight;
  }
}


.backtohome {
  @apply bg-white text-sm font-semibold flex gap-2 items-center px-4 py-2 rounded-lg;

  box-shadow: 0px 2px 0 rgb(145, 145, 145);
}