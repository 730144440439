.requestdata-header {
  @apply flex flex-wrap items-center justify-between gap-6 bg-indigodark p-5 sm:py-6 sm:px-8;
}

.requestdata {
  @apply my-3 h-full flex flex-col gap-y-3 bg-white p-5 sm:py-6 sm:px-8;
  width: 90%;
  max-width: 1200px;

  & .head {
    @apply pb-3 border-b border-gray-200;

    & small {
      @apply text-gray-500;
    }
  }

  .h4 {
    margin: 5px 0px;
    padding: 5px 0px;
  }

  .paragraph > p{
    margin: 5px 0px
  }
}




.backtohome {
  @apply bg-white text-sm font-semibold flex gap-2 items-center px-4 py-2 rounded-lg;

  box-shadow: 0px 2px 0 rgb(145, 145, 145);
}