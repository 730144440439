h2.pre-review-options {
    transition: 0.2s border;
}
h2.pre-review-options.active {
    border: 2px solid skyblue;
}

.pre-review-loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #2f6e9b;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 1.5s linear infinite;
    margin-top: 50px !important;
    margin-bottom: 50px;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
 