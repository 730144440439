@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-size: 16px;
    scroll-behavior: smooth;
  }

  @media screen and (max-width: 500px) {
    html {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 375px) {
    html {
      font-size: 12px;
    }
  }

  * {
    font-family: -apple-system, BlinkMacSystemFont, "Red Hat Display",
      "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
      "Helvetica Neue", sans-serif;
  }

  body {
    font-family: -apple-system, BlinkMacSystemFont, "Red Hat Display",
      "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
      "Helvetica Neue", sans-serif;
    overflow-x: hidden;
  }

  a,
  button,
  input,
  textarea {
    &:focus {
      outline: 2px dotted orange;
    }
  }
}

@layer components {
  /* Typography */
  .h1 {
    @apply text-4xl font-extrabold tracking-tighter;
  }

  .h2 {
    @apply text-3xl font-extrabold tracking-tighter;
  }

  .h3 {
    @apply text-3xl font-extrabold tracking-tight;
  }

  .h4 {
    @apply text-2xl font-extrabold tracking-tight;
  }

  .h5 {
    @apply text-xl font-extrabold tracking-tight;
  }

  @screen md {
    .h1 {
      @apply text-5xl;
    }

    .h2 {
      @apply text-4xl;
    }
  }
}

.logo {
  fill: black;
  & .fil1 {
    fill: #262262;
  }

  & .fil0 {
    fill: #40c8f4;
  }

  &.fil2 {
    fill: #262262;
    fill-rule: nonzero;
  }
}

.logo-dark {
  fill: white;
  & .fil1 {
    fill: white;
  }

  & .fil0 {
    fill: #40c8f4;
  }

  &.fil2 {
    fill: white;
    fill-rule: nonzero;
  }
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.pace .pace-progress {
  height: 4px;
}

.filterable-table-container .header-row {
  margin-top: 6px;
}
.filterable-table-container .header-row .exact-filters {
  margin-top: 20px;
}
.filterable-table-container .header-row .record-count {
  padding-top: 7px;
}
.filterable-table-container table.filterable-table thead tr th {
  white-space: nowrap;
  user-select: none;
}
.filterable-table-container table.filterable-table thead tr th span.fa {
  padding-left: 8px;
  color: #aaa;
}
.filterable-table-container table.filterable-table tfoot tr td {
  font-weight: 700;
}
.filterable-table-container .filter-container {
  position: relative;
}
.filterable-table-container .filter-container .filter-input,
.filterable-table-container .filter-container .page-size {
  width: 185px;
  display: inline;
}
.filterable-table-container .clear-filter {
  position: absolute;
  right: 5px;
  top: 1px;
}
.filterable-table-container span.sortable,
.filterable-table-container span.filterable {
  cursor: pointer;
}
.filterable-table-container span.filterable {
  border-bottom: 1px solid #91c1ff;
}
.filterable-table-container ul.pagination li a {
  user-select: none;
}
.filterable-table-container ul.pagination li:not(.disabled) a {
  cursor: pointer;
}
.filterable-table-container span.empty {
  font-style: italic;
  color: #999;
}
.filterable-table-container .filter-item {
  border: 1px solid #ccc;
  border-radius: 1px;
  display: inline-block;
  margin-right: 15px;
}
.filterable-table-container .filter-item .filter-item-title {
  background-color: #2f6e9b;
  border-radius: 1px;
  color: #fff;
}
.filterable-table-container
  .filter-item
  .filter-item-title
  .filter-item-remove {
  color: #fff;
  font-weight: 700;
  user-select: none;
  padding-right: 10px;
  cursor: pointer;
}
.filterable-table-container .filter-item .filter-item-title,
.filterable-table-container .filter-item .filter-item-value {
  padding: 5px 10px;
  display: inline-block;
}
