.nigeriamap {
    & .state:hover {
        & path:first-child {
            fill: #262262;
        }

        & path:last-child {
            fill: #fff;
        }
    }
}